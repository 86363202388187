@import '~eksenia-lib/src/styles/_constants.scss';

.reset-password {
    form {
        width: 600px;
        margin: 100px auto 0;
    }

    .input-label {
        text-align: right;
        width: 200px;
    }

    .button {
        float: right;
        margin-right: 120px;
    }
}
