// colors
$color-background-primary: #fff;
$color-neutral-1: grey;
$color-neutral-2: #8391ae;

$color-input-primary: #ea6153;
$color-input-background: #fff3eb;
$color-input-hover: #ffa062;
$color-input-disabled: #eee;
$color-input-disabled-2: #ccc;

$color-header-primary: #3b5998;
$color-header-secondary: #5f7ec1;

$color-element-head-primary: #7c96cc;

$color-error: #cc0000;
$color-success: #37ab73;

// text
$font-family: 'OpenSans', sans-serif;

$font-size-m: 14px;
$font-size-l: 18px;

$font-weight-m: 400;
$font-weight-l: 700;

// sizes
$size-xs: 5px;
$size-s: 10px;
$size-m: 15px;
$size-l: 20px;
$size-xl: 25px;
$size-2xl: 30px;

$vertical-form-label-width: 300px;

// borders and shadows
$border-radius-m: 2px;

$shadow-standard: 0px 0px 2px 1px #ddd;
